import { Box, styled } from "@mui/material";

export const HomeStyled = styled(Box)`
text-align: center;
    h1{
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
        line-height: 1.5;
    }
    p{
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;
    }
`