/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import { FooterStyled } from '../StyledComponents/FooterStyled'
import { asset } from '../json/asset'
import { Box } from '@mui/material'

const Footer = () => {
  return (
    <FooterStyled>
    <Box className="footerBg" style={{ backgroundImage: `url(${asset.footerBg})`, backgroundSize: '100% 100%', backgroundPosition: 'center'  }}>
        <img src={asset.footerLogo} alt="footer image" />
      </Box>
    </FooterStyled>
  )
}

export default Footer