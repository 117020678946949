import { Box, styled } from "@mui/material";

export const QrScanStyled = styled(Box)`
    p{
        font-size: 16px;
        font-weight: 600;
        text-align: center;
    }
    h4{
        text-align: center;
        font-size: 18px;
        font-weight: 600;
    }
    .qrScanner{
        display: flex;
        justify-content: center;
        margin-bottom: 25px;
        .qrScannerBox{
            width: 250px;
            height: 250px;
            border: 6px solid var(--yellow);
            border-radius: 50px;
            overflow: hidden;
            position: relative;

            svg{
                path{
                    stroke: var(--yellow);
                }
            }

            .qrVideoWrap {
                position: static !important;
                width: 100% !important;
                height: 100% !important;

            }
            
            video{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .cameraError{
        padding: 120px 0px 50px;
    }

    
`