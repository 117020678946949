import React, { useEffect, useState } from 'react'
import Wrapper from '../layout/Wrapper'
import { Box, Typography } from '@mui/material'
import { ScanResultStyled } from '../StyledComponents/ScanResultStyled'
import { asset } from '../json/asset'
import { useNavigate } from 'react-router-dom'
const ScanSuccessful = () => {
    const scannedCoinNo = Number(localStorage.getItem('scannedCoinNo') || '0');
    const navigate = useNavigate();
    const [timeLeft, setTimeLeft] = useState(5);

    useEffect(() => {
        if (timeLeft === 0) {
            navigate('/scan'); // Replace with your target route
            return;
        }
        const timerId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(timerId);
        // eslint-disable-next-line
    }, [timeLeft]);
    return (
        <Wrapper>
            <ScanResultStyled>
                <Box className='coinAnimation '>
                    <figure className='foundCoinAni'>
                        <img src={asset.foundCoinGif} alt="found coin animation" />
                    </figure>
                </Box>

                <Typography variant='h4'>
                    You found a coin! <br />
                    <Typography variant='span' className='coinStatus'>
                        {scannedCoinNo}/5 coins collected...
                    </Typography>
                </Typography>
                <Typography variant='body1'>
                    You will be redirected in {timeLeft} seconds...
                </Typography>
            </ScanResultStyled>
        </Wrapper>
    )
}

export default ScanSuccessful