import React from 'react'
import { QrScanStyled } from '../StyledComponents/QrScan'
import Wrapper from '../layout/Wrapper'
import { Box, Typography } from '@mui/material'
import { useEffect, useRef, useState } from "react";
// Qr Scanner
import QrScanner from "qr-scanner";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';

const QrScan = () => {



  // const Sheet_Url = 'https://script.google.com/macros/s/AKfycbwzhI8FsifZcFT0zqQsXCm8eMB2fXxTiuL-tNDKBc9RHcjxCELhvqpYMWsJDhpDWolc/exec';

  const Sheet_Url = 'https://script.google.com/macros/s/AKfycbwbcgQaTNW-gO-A4KQP3L3e0-b3c6-OviwMZ1zcrY_a3uR04ITrEYLrSDKQtpJVpPIB/exec';
  function addOrUpdateSheet() {
    const data = new FormData();
    data.append('Date', moment().format('MM/DD/YYYY'));
    try {
      axios.post(Sheet_Url, data).then();
      return 'success'
    } catch (error) {
      return 'error';
    }
  }

  // QR States
  const scanner = useRef();
  const videoEl = useRef(null);
  const qrBoxEl = useRef(null);
  const [qrOn, setQrOn] = useState(true);

  const navigate = useNavigate();
  const onScanSuccess = async (result) => {
    if(result.data){
      throttledValidateCode(result.data);
    }
  };
  const throttledValidateCode = throttle(validateCode, 2000);
  function throttle(func, delay) {
    let lastCall = 0;
    return function(...args) {
      const now = new Date().getTime();
      if (now - lastCall >= delay) {
        lastCall = now;
        return func(...args);
      }
    };
  }
  function validateCode(value){
    if(!value.includes('coin')){
      navigate('/wrong-coin');
      return;
    }
    const coinNo = value.split('-')[1];
    let scannedCoinNo = Number(localStorage.getItem('scannedCoinNo') || '0');
    const scannedCoins = (localStorage.getItem('scannedCoins') || '');
    if (coinNo === '0') {
      navigate('/wrong-coin');
      return;
    }
    else if (!scannedCoins.includes(coinNo)) {
      localStorage.setItem('scannedCoins', scannedCoins + ',' + coinNo);
      scannedCoinNo += 1;
      localStorage.setItem('scannedCoinNo', scannedCoinNo);
      if (scannedCoinNo === 5) {
        navigate('/congratulations');
        addOrUpdateSheet();
        return;
      } else {
        navigate('/found-coin');
        return;
      }
    } else {
      navigate('/wrong-coin?type=sameCoin');
      return;
    }
  }

  // Fail
  const onScanFail = (err) => {
    // 🖨 Print the "err" to browser console.
    console.log(err);
  };

  useEffect(() => {
    if (videoEl?.current && !scanner.current) {
      // 👉 Instantiate the QR Scanner
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: onScanFail,
        // 📷 This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
        preferredCamera: "environment",
        // 🖼 This will help us position our "QrFrame.svg" so that user can only scan when qr code is put in between our QrFrame.svg.
        highlightScanRegion: true,
        // 🔥 This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
        highlightCodeOutline: true,
        // 📦 A custom div which will pair with "highlightScanRegion" option above 👆. This gives us full control over our scan region.
        overlay: qrBoxEl?.current || undefined,
      });

      // 🚀 Start QR Scanner
      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }

    // 🧹 Clean up on unmount.
    // 🚨 This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
    return () => {
      // eslint-disable-next-line
      if (!videoEl?.current) {
        scanner?.current?.stop();
      }
    };
    // eslint-disable-next-line
  }, []);

  // ❌ If "camera" is not allowed in browser permissions, show an alert.
  useEffect(() => {
    if (!qrOn)
      alert(
        "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
      );
  }, [qrOn]);

  return (
    <Wrapper>
      <QrScanStyled>
        {qrOn ?
          <>
            <Box className="qrScanner">
              <Box className="qrScannerBox" >
                <Box className="qrVideoWrap" ref={qrBoxEl}>
                  <video ref={videoEl} />
                </Box>
              </Box>
            </Box>
            <Typography variant='h4'>
              Scan the QR code to collect <br /> CelcomDigi coins.
            </Typography>
          </>
          :
          <Box className='cameraError'>
            <Typography variant='h4'>
              Please allow camera in your browser setting and reload.
            </Typography>
          </Box>
        }
      </QrScanStyled>
    </Wrapper>
  );
};

export default QrScan;