import React, { useState, useEffect } from 'react';
import Wrapper from '../layout/Wrapper';
import { ScanResultStyled } from '../StyledComponents/ScanResultStyled';
import { Box, Typography } from '@mui/material';
import { asset } from '../json/asset';
import { useNavigate } from 'react-router-dom';

const ScanFailed = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const paramValue = searchParams.get('type');
    const navigate = useNavigate();
    const [timeLeft, setTimeLeft] = useState(5);

    useEffect(() => {
        if (timeLeft === 0) {
            navigate('/scan'); // Replace with your target route
            return;
        }
        const timerId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(timerId);
        // eslint-disable-next-line
    }, [timeLeft]);

    return (
        <Wrapper>
            <ScanResultStyled>
                <Box className='coinAnimation'>
                    <figure>
                        <img src={asset.wrongCoinGif} alt="wrong coin animation" />
                    </figure>
                </Box>
                {
                    (paramValue === 'sameCoin') ?
                        <Typography variant='h4'>
                            Woops! You scanned the same coin again.
                        </Typography>
                        :
                        <Typography variant='h4'>
                            Woops! Not the right coin...
                        </Typography>
                }
                <Typography variant='body1'>
                    You will be redirected in {timeLeft} seconds...
                </Typography>
            </ScanResultStyled>
        </Wrapper>
    );
}

export default ScanFailed;