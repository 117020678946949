import { Box, styled } from "@mui/material";

export const CustomLoaderStyled = styled(Box)`
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: var(--white);
    .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity 1s ease-in-out;

    &.fade-out {
        opacity: 0;
    }
}

.bouncing-dots {
    display: flex;
    justify-content: space-between;
    width: 55px;
    margin-top: 20px;

    .dot {
    width: 12px;
    height: 12px;
    /* background-color: #FF5C35; */
    border-radius: 50%;
    animation: bounce 1.5s infinite;

    &:nth-of-type(1) {
      animation-delay: 0s;
    background-color: var(--yellow);

    }

    &:nth-of-type(2) {
        animation-delay: 0.3s;
    background-color: var(--yellow);
    

    }

    &:nth-of-type(3) {
        animation-delay: 0.6s;
    background-color: var(--yellow);

    }
}

}




@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}
    
`