import { Box, Container } from '@mui/material'
import React from 'react'
import Header from './Header'
import Footer from './Footer'


const Wrapper = ({children}) => {
  return (
    <Box className="body_content">
        <Box className="body_children">
          <Header />
          <Container maxWidth="false">
            {children}
          </Container>
        </Box>
          <Footer />
    </Box>
  )
}

export default Wrapper