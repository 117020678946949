import { Box } from '@mui/material'
import React from 'react'
import { CustomLoaderStyled } from '../StyledComponents/CustomLoaderStyled'
import { asset } from '../json/asset'


const Loader = () => {
  return (
    <CustomLoaderStyled>
      <img src={asset.loaderLogo} alt="loader logo" />
      <Box className="loader-container">
        <Box className="bouncing-dots">
          <Box className="dot"></Box>
          <Box className="dot"></Box>
          <Box className="dot"></Box>
        </Box>
      </Box>
    </CustomLoaderStyled>
  )
}

export default Loader