export const asset = {
    logo: "assets/images/logo.svg",
    footerLogo: "assets/images/footerLogo.png",
    footerBg: "assets/images/footerBg.png",
    loaderLogo: "assets/images/loaderLogo.svg",
    foundCoinGif: "assets/images/foundCoin.gif",
    wrongCoinGif: "assets/images/wrongCoin.gif",
    congratulationsGif: "assets/images/congratulations.gif",
    qrFrame: "assets/images/qr-frame.svg",
}
