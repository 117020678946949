import { Box, styled } from "@mui/material";

export const HeaderStyled = styled(Box)`
padding-bottom: 30px;
.header_content{
    text-align: center;
    display: flex;
    justify-content: center;
    .logoLink{
        display: inline-flex;
    }
}
`