import React, { useEffect } from 'react'
import Wrapper from '../layout/Wrapper'
import { Box, Typography } from '@mui/material'
import { HomeStyled } from '../StyledComponents/HomeStyled'
import { Link } from 'react-router-dom'
import Loader from '../components/Loader'

const Home = () => {
  const [showLoader,setShowLoader] = React.useState(true)
  useEffect(()=>{
    setTimeout(function() {
      setShowLoader(false)
         }, 3000);
       },
   [])
  return (
    <Wrapper>
      <HomeStyled>
        <Typography variant='h1'>
          Welcome to Disney+ Hotstar <br />
          The Zone Survival Mission S3 <br />
          Break House Experience.
        </Typography>
        <Typography variant='body1'>
        Your task is to find the hidden CelcomDigi coins scattered around this room by scanning a QR code.
        </Typography>
        <Typography variant='body1'>
          Collect all 5 CelcomDigi coins to enjoy a little surprise from us to you!
        </Typography>

        <Box className="cmnBtn_center">
          <Link to="/scan" className='cmnBtn'>
            Start
          </Link>
        </Box>
      </HomeStyled>
      {
        showLoader && <Loader />
      }

    </Wrapper>
  )
}

export default Home