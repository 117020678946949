import { Box, styled } from "@mui/material";

export const ScanResultStyled = styled(Box)`
.coinAnimation {
        display: flex;
        justify-content: center;
        figure{
            margin: 0 auto;
            overflow: hidden;

            img{
                width: 100%;
                object-fit: contain;
            }

            &.congratulationsAni{
                max-width: 400px;
            }

            &.foundCoinAni{
                height: 250px;
                max-width: 300px;
            }
        }

        
    }
    h4{ 
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 10px;
        /* &:last-of-type{
            margin-bottom: 0;
        }

        .coinStatus{
            font-size: 18px;
        } */
    }
    p{
        font-size: 18px;
        font-weight: 600;
        text-align: center;
    }
    
`