import { Box, styled } from "@mui/material";

export const FooterStyled = styled(Box)`
/* position: absolute;
left: 0;
bottom: 0; */
width: 100%;
   text-align: center;

   .footerBg{
      padding: 18px 0 17px;
      line-height: 0;
      text-align: center;
      img{
         width: 130px;
      }
   }
`