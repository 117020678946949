import { createBrowserRouter, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import QrScan from "../pages/QrScan";
import ScanSuccessful from "../pages/ScanSuccessful";
import ScanFailed from "../pages/ScanFailed";
import Congratulations from "../pages/Congratulations";






export const router = createBrowserRouter([
    {
        path: "/home",
        element: <Home />,
    },
    {
        path: "/scan",
        element: <QrScan />,
    },
    {
        path: "/found-coin",
        element: <ScanSuccessful />,
    },
    {
        path: "/wrong-coin",
        element: <ScanFailed />,
    },
    {
        path: "/congratulations",
        element: <Congratulations />,
    },
    
 
    {
        path: "*",
        element: <Navigate to="/home" />,
    }

]);