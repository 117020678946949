import React from 'react'
import { HeaderStyled } from '../StyledComponents/HeaderStyled'
import { Box, Container } from '@mui/material'
import { asset } from '../json/asset'
import { Link } from 'react-router-dom'

const Header = () => {
    return (
        <HeaderStyled>
            <Container maxWidth="false">
                <Box className="header_content">
                    <Link className='logoLink' to="/home"><img src={asset.logo} alt="" /></Link>
                </Box>
            </Container>
        </HeaderStyled>
    )
}

export default Header