import React from 'react'
import Wrapper from '../layout/Wrapper'
import { ScanResultStyled } from '../StyledComponents/ScanResultStyled'
import { Box, Typography } from '@mui/material'
import { asset } from '../json/asset'

const Congratulations = () => {
    return (
        <Wrapper>
            <ScanResultStyled>
                <Box className='coinAnimation'>
                    <figure className='congratulationsAni'>
                        <img src={asset.congratulationsGif} alt="" />
                    </figure>
                </Box>
                <Typography variant='h4'>
                    Congratulations! You collected all 5 coins. Show this to our crew for next step.
                </Typography>
            </ScanResultStyled>
        </Wrapper>
    )
}

export default Congratulations